import React, { useEffect } from 'react'

import { EuiButton, EuiButtonIcon, EuiCallOut, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { GeoCircle, GeoFence, GeoLocationTarget, GeoPolygon, GeoRectangle, LocationCity, LocationDma, LocationPostalCode, LocationRegion } from 'api'
import { LocationTargetingType } from 'api/rtkQueryApi/opsApi/campaignsApi'
import { useLazyGetPostalCodesCsvQuery } from 'api/rtkQueryApi/opsApi/targetingApi'
import { AcTargeterSelection } from 'components/ACTools/AcTargeterSelection'
import { AddressFinder } from 'components/Finders/AddressFinder'
import { CityFinderXandr } from 'components/Finders/CityFinderXandr'
import { DMAFinderXandr } from 'components/Finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from 'components/Finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from 'components/Finders/RegionFinderXandr'
import TargetingMap from 'components/TargetingMap'

export interface IModernLocationTargeting {
  locations: GeoLocationTarget
  selectedLocationType: LocationTargetingType
  onLocationsChanged?: (locations: GeoLocationTarget) => void
  onSelectedLocationTypeChanged?: (locationType: LocationTargetingType) => void
}

export const ModernLocationTargeting: React.FC<IModernLocationTargeting> = props => {
  const [pastedZips, setPastedZips] = React.useState('')
  const [getZipsFromCsv, getZipsFromCsvQuery] = useLazyGetPostalCodesCsvQuery()
  const [zipsAsCsv, setZipsAsCsv] = React.useState('')

  useEffect(() => {
    if (props.locations.postalCodes) {
      setZipsAsCsv(props.locations.postalCodes?.map(code => code.code).join(',') ?? '')
    }
  }, [props.locations.postalCodes])

  const onLocationTypeChange = (locationType: LocationTargetingType) => {
    props.onSelectedLocationTypeChanged?.(locationType)
  }

  const onRegionSelected = (region: LocationRegion) => {
    props.onLocationsChanged?.({
      ...props.locations,
      regions: [...(props.locations.regions ?? []), region]
    })
  }

  const onRegionRemoved = (id: string) => {
    props.onLocationsChanged?.({
      ...props.locations,
      regions: props.locations.regions?.filter(region => region.id.toString() !== id) ?? []
    })
  }

  const onDmaSelected = (dma: LocationDma) => {
    props.onLocationsChanged?.({
      ...props.locations,
      dmas: [...(props.locations.dmas ?? []), dma]
    })
  }

  const onDmaRemoved = (id: string) => {
    props.onLocationsChanged?.({
      ...props.locations,
      dmas: props.locations.dmas?.filter(dma => dma.id.toString() !== id) ?? []
    })
  }

  const onAddressSelected = (address: GeoFence) => {
    props.onLocationsChanged?.({
      ...props.locations,
      geoFences: [...(props.locations.geoFences ?? []), address]
    })
  }

  const onAddressRemoved = (id: string) => {
    props.onLocationsChanged?.({
      ...props.locations,
      geoFences: props.locations.geoFences?.filter(address => address.placeId.toString() !== id) ?? []
    })
  }

  const onCitySelected = (city: LocationCity) => {
    props.onLocationsChanged?.({
      ...props.locations,
      cities: [...(props.locations.cities ?? []), city]
    })
  }

  const onCityRemoved = (id: string) => {
    props.onLocationsChanged?.({
      ...props.locations,
      cities: props.locations.cities?.filter(city => city.id.toString() !== id) ?? []
    })
  }

  const onZipSelected = (postalCode: LocationPostalCode) => {
    props.onLocationsChanged?.({
      ...props.locations,
      postalCodes: [...(props.locations.postalCodes ?? []), postalCode]
    })
  }

  const onZipRemoved = (id: string) => {
    props.onLocationsChanged?.({
      ...props.locations,
      postalCodes: props.locations.postalCodes?.filter(code => code.id.toString() !== id) ?? []
    })
  }

  const onPasteZipsClick = async () => {
    const zips = pastedZips.replaceAll(/\s/g, '')
    const zipResult = await getZipsFromCsv({ codeCsv: zips })

    setPastedZips('')
    props.onLocationsChanged?.({
      ...props.locations,
      postalCodes: props.locations.postalCodes.concat(zipResult.data ?? [])
    })
  }

  return (
    <React.Fragment>
      <EuiRadio
        id='us'
        name='locationType'
        value='USA'
        label={
          <EuiText size='s'>
            <strong>Targeting Entire USA</strong>
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.USA || props.selectedLocationType === LocationTargetingType.Country}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.USA)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Targeting Using State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.State || props.selectedLocationType === LocationTargetingType.Region}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.State)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.DMA}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.DMA)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>Targeting Using City</strong> (one or more City names)
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.City}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.City)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='address'
        name='locationType'
        value='Address'
        label={
          <EuiText size='s'>
            <strong>Target by Street Addresses</strong> (one or more)
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.Address}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.Address)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.Zip}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.Zip)
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='map'
        name='locationType'
        value='Map'
        label={
          <EuiText size='s'>
            <strong>Target on Map</strong> (draw the areas you want to target on a map)
          </EuiText>
        }
        checked={props.selectedLocationType === LocationTargetingType.Map}
        onChange={() => {
          onLocationTypeChange(LocationTargetingType.Map)
        }}
      />
      <EuiSpacer size='s' />
      {props.selectedLocationType === LocationTargetingType.State && (
        <React.Fragment>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr onOptionClick={onRegionSelected} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={props.locations.regions?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onRegionRemoved(item.key)
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationTargetingType.DMA && (
        <React.Fragment>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={onDmaSelected} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={props.locations.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onDmaRemoved(item.key)
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationTargetingType.City && (
        <React.Fragment>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={onCitySelected} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={props.locations.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onCityRemoved(item.key)
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationTargetingType.Address && (
        <React.Fragment>
          <EuiFormRow fullWidth label={'Addresses'}>
            <AddressFinder onOptionClick={onAddressSelected} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={props.locations.geoFences?.map(r => ({ key: r.placeId.toString(), label: `${r.formattedAddress}` })) ?? []}
              onItemRemoved={item => {
                onAddressRemoved(item.key)
              }}
            />
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationTargetingType.Zip && (
        <React.Fragment>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={onZipSelected} />
          </EuiFormRow>
          <EuiFormRow fullWidth label={'Paste Zips as CSV'}>
            <EuiTextArea placeholder='Paste zips as comma seperated list (ex:"12345,23456")' value={pastedZips} onChange={e => setPastedZips(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton size='s' onClick={onPasteZipsClick} isLoading={getZipsFromCsvQuery.isLoading}>
                  {getZipsFromCsvQuery.isLoading ? 'Getting Zips Codes...' : 'Submit Zips'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={props.locations.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onZipRemoved(item.key)
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={zipsAsCsv}>{copy => <EuiButtonIcon title={'Copy as CSV'} iconType={'copy'} onClick={copy} />}</EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </React.Fragment>
      )}
      {props.selectedLocationType === LocationTargetingType.Map && (
        <React.Fragment>
          <EuiCallOut size='s' iconType='mapMarker' color='success' title='If you do not draw an area to target, the entire USA will be targeted by default.' />
          <EuiSpacer size='s' />
          {props.selectedLocationType === LocationTargetingType.Map && (
            <TargetingMap
              geoCircles={props.locations.geoCircles ?? []}
              addCircle={(circle: GeoCircle) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoCircles: [...(props.locations.geoCircles ?? []), circle]
                })
              }}
              removeCircle={(id: string) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoCircles: props.locations.geoCircles?.filter(c => c.id !== id) ?? []
                })
              }}
              modifyCircle={(circle: GeoCircle) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoCircles:
                    props.locations.geoCircles?.map(c => {
                      if (c.id !== circle.id) {
                        return c
                      }

                      return circle
                    }) ?? []
                })
              }}
              geoRectangles={props.locations.geoRectangles ?? []}
              addRectangle={(rectangle: GeoRectangle) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoRectangles: [...(props.locations.geoRectangles ?? []), rectangle]
                })
              }}
              removeRectangle={(id: string) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoRectangles: props.locations.geoRectangles?.filter(r => r.id !== id) ?? []
                })
              }}
              modifyRectangle={(rectangle: GeoRectangle) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoRectangles:
                    props.locations.geoRectangles?.map(r => {
                      if (r.id !== rectangle.id) {
                        return r
                      }

                      return rectangle
                    }) ?? []
                })
              }}
              geoPolygons={props.locations.geoPolygons ?? []}
              addPolygon={(polygon: GeoPolygon) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoPolygons: [...(props.locations.geoPolygons ?? []), polygon]
                })
              }}
              removePolygon={(id: string) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoPolygons: props.locations.geoPolygons?.filter(p => p.id !== id) ?? []
                })
              }}
              modifyPolygon={(polygon: GeoPolygon) => {
                props.onLocationsChanged?.({
                  ...props.locations,
                  geoPolygons:
                    props.locations.geoPolygons?.map(p => {
                      if (p.id !== polygon.id) {
                        return p
                      }

                      return polygon
                    }) ?? []
                })
              }}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
