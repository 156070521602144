import { useEffect, useState } from 'react'

import { EuiButton, EuiButtonIcon, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadio, EuiSpacer, EuiText, EuiTextArea } from '@elastic/eui'

import { useLazyGetPostalCodesCsvQuery } from 'api/rtkQueryApi/opsApi/targetingApi'
import { AcTargeterSelection } from 'components/ACTools/AcTargeterSelection'
import { CityFinderXandr } from 'components/Finders/CityFinderXandr'
import { DMAFinderXandr } from 'components/Finders/DMAFinderXandr'
import { PostalCodeFinderXandr } from 'components/Finders/PostalCodeFinderXandr'
import { RegionFinderXandr } from 'components/Finders/RegionFinderXandr'

import { City, Dma, LocationTargeting, PostalCode } from '../../api/entities/Targeting'

export const LocationTargetingForm = ({ locationTargeting, onLocationTargetingChange }: { locationTargeting: LocationTargeting; onLocationTargetingChange: (locationTargeting: LocationTargeting) => void }) => {
  const [pastedZips, setPastedZips] = useState('')
  const [getZipsFromCsv, getZipsFromCsvQuery] = useLazyGetPostalCodesCsvQuery()
  const [zipsAsCsv, setZipsAsCsv] = useState('')
  useEffect(() => {
    locationTargeting.postalCodes && setZipsAsCsv(locationTargeting.postalCodes?.map(r => r.code).join(','))
  }, [locationTargeting.postalCodes])

  const onPasteZipsClick = async () => {
    const zips = pastedZips.replaceAll(/\s/g, '')
    const zipResult = await getZipsFromCsv({ codeCsv: zips })

    setPastedZips('')
    onLocationTargetingChange({
      ...locationTargeting,
      postalCodes: locationTargeting.postalCodes ? locationTargeting.postalCodes?.concat(zipResult.data ?? []) : zipResult.data ?? []
    })
  }

  return (
    <>
      <EuiRadio
        id='state'
        name='locationType'
        value='State'
        label={
          <EuiText size='s'>
            <strong>Targeting Using State</strong> (one or more U.S. States)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Region'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'Region' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='dma'
        name='locationType'
        value='DMA'
        label={
          <EuiText size='s'>
            <strong>Targeting Using DMA/Metro Area</strong> (one or more DMAs)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'DMA'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'DMA' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='city'
        name='locationType'
        value='City'
        label={
          <EuiText size='s'>
            <strong>Targeting Using City</strong> (one or more City names)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'City'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'City' })
        }}
      />
      <EuiSpacer size='s' />
      <EuiRadio
        id='zip'
        name='locationType'
        value='Zip'
        label={
          <EuiText size='s'>
            <strong>Targeting Using Zip Code</strong> (one or more Zip Codes)
          </EuiText>
        }
        checked={locationTargeting.locationType === 'Zip'}
        onChange={() => {
          onLocationTargetingChange({ ...locationTargeting, locationType: 'Zip' })
        }}
      />
      <EuiSpacer size='s' />
      {locationTargeting.locationType === 'Region' && (
        <>
          <EuiFormRow fullWidth label={'Region'}>
            <RegionFinderXandr
              onOptionClick={region => {
                onLocationTargetingChange({ ...locationTargeting, regions: [...(locationTargeting.regions ?? []), region] })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.regions?.map(r => ({ key: r.id.toString(), label: r.name ?? '' })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, regions: locationTargeting.regions?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'DMA' && (
        <>
          <EuiFormRow fullWidth label={'DMAs'}>
            <DMAFinderXandr onOptionClick={(dma: Dma) => onLocationTargetingChange({ ...locationTargeting, dmas: [...(locationTargeting.dmas ?? []), dma] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.dmas?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, dmas: locationTargeting.dmas?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'City' && (
        <>
          <EuiFormRow fullWidth label={'Cities'}>
            <CityFinderXandr onOptionClick={(city: City) => onLocationTargetingChange({ ...locationTargeting, cities: [...(locationTargeting.cities ?? []), city] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.cities?.map(r => ({ key: r.id.toString(), label: `${r.name}, ${r.regionCode}` })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, cities: locationTargeting.cities?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
        </>
      )}
      {locationTargeting.locationType === 'Zip' && (
        <>
          <EuiFormRow fullWidth label={'Zips'}>
            <PostalCodeFinderXandr onOptionClick={(zip: PostalCode) => onLocationTargetingChange({ ...locationTargeting, postalCodes: [...(locationTargeting.postalCodes ?? []), zip] })} />
          </EuiFormRow>
          <EuiFormRow fullWidth label={'Paste Zips as CSV'}>
            <EuiTextArea placeholder='Paste zips as comma seperated list (ex:"12345,23456")' value={pastedZips} onChange={e => setPastedZips(e.target.value)} />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButton size='s' onClick={onPasteZipsClick} isLoading={getZipsFromCsvQuery.isLoading}>
                  {getZipsFromCsvQuery.isLoading ? 'Getting Zips Codes...' : 'Submit Zips'}
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <AcTargeterSelection
              items={locationTargeting.postalCodes?.map(r => ({ key: r.id.toString(), label: r.name })) ?? []}
              onItemRemoved={item => {
                onLocationTargetingChange({ ...locationTargeting, postalCodes: locationTargeting.postalCodes?.filter(r => r.id.toString() !== item.key) })
              }}
            />
          </EuiFormRow>
          <EuiFormRow fullWidth>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiCopy textToCopy={zipsAsCsv}>{copy => <EuiButtonIcon title={'Copy as CSV'} iconType={'copy'} onClick={copy} />}</EuiCopy>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFormRow>
        </>
      )}
    </>
  )
}
