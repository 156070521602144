import { useEffect, useState } from 'react'

import { EuiCheckbox, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { CampaignType } from '../../api'
import { TvTargeting, Segment, BaseTargeting, LocationTargeting, AudienceTargeting } from '../../api/entities/Targeting'
import { DEFAULT_AUDIENCE_TARGETING, DEFAULT_LOCATION_TARGETING } from '../../utils/TargetingFormDefaults'
import { AudienceTargetingForm } from './AudienceTargetingForm'
import { CampaignDeliveryForm } from './CampaignDeliveryForm'
import { LocationTargetingForm } from './LocationTargetingForm'
import { SegmentsForm } from './SegmentsForm'

type TvTargetingFormProps = {
  tvTargeting: TvTargeting
  onTvTargetingChanged: (tvTargeting: TvTargeting) => void
}

export const TvTargetingForm = ({ tvTargeting, onTvTargetingChanged }: TvTargetingFormProps) => {
  const [editCampaignDelivery, setEditCampaignDelivery] = useState(false)
  const [editLocationTargeting, setEditLocationTargeting] = useState(!!tvTargeting.locationTargeting)
  const [editDemographics, setEditDemographics] = useState(!!tvTargeting.audienceTargeting)

  const onSegmentsChange = (segments: Segment[]) => {
    onTvTargetingChanged({
      ...tvTargeting,
      segments
    })
  }

  const onCampaignDeliveryChange = (campaignDelivery: BaseTargeting) => {
    onTvTargetingChanged({
      ...tvTargeting,
      ...campaignDelivery
    })
  }

  const onLocationTargetingChange = (locationTargeting: LocationTargeting) => {
    onTvTargetingChanged({
      ...tvTargeting,
      locationTargeting
    })
  }

  const onDemographicsChange = (audienceTargeting: AudienceTargeting) => {
    onTvTargetingChanged({
      ...tvTargeting,
      audienceTargeting
    })
  }

  useEffect(() => {
    if (!editLocationTargeting) {
      onTvTargetingChanged({
        ...tvTargeting,
        locationTargeting: undefined
      })
    } else if (!tvTargeting.locationTargeting) {
      onTvTargetingChanged({
        ...tvTargeting,
        locationTargeting: DEFAULT_LOCATION_TARGETING
      })
    }
  }, [editLocationTargeting])

  useEffect(() => {
    if (!editDemographics) {
      onTvTargetingChanged({
        ...tvTargeting,
        audienceTargeting: undefined
      })
    } else if (!tvTargeting.audienceTargeting) {
      onTvTargetingChanged({
        ...tvTargeting,
        audienceTargeting: DEFAULT_AUDIENCE_TARGETING
      })
    }
  }, [editDemographics])

  return (
    <>
      <EuiTitle size={'s'}>
        <h3>Location Targeting</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiCheckbox id={'editInternetLocationTargeting'} onChange={() => setEditLocationTargeting(!editLocationTargeting)} checked={editLocationTargeting} label={'Override'} />
      <EuiSpacer size={'xs'} />
      {editLocationTargeting && <LocationTargetingForm locationTargeting={tvTargeting.locationTargeting ?? DEFAULT_LOCATION_TARGETING} onLocationTargetingChange={onLocationTargetingChange} />}
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h3>Demographics</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiCheckbox id={'editDemographicsTargeting'} onChange={() => setEditDemographics(!editDemographics)} checked={editDemographics} label={'Override'} />
      <EuiSpacer size='xs' />
      {editDemographics && <AudienceTargetingForm audienceTargeting={tvTargeting.audienceTargeting ?? DEFAULT_AUDIENCE_TARGETING} onAudienceTargetingChanged={onDemographicsChange} />}
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h3>Audience Targeting</h3>
      </EuiTitle>
      <EuiSpacer size='xs' />
      <SegmentsForm campaignType={CampaignType.TV} segments={tvTargeting.segments ?? []} onSegmentsChange={onSegmentsChange} />
      <EuiSpacer />
      <EuiSwitch label={'Edit Campaign Delivery Strategy'} checked={editCampaignDelivery} onChange={() => setEditCampaignDelivery(!editCampaignDelivery)} />
      <EuiSpacer />
      {editCampaignDelivery && (
        <>
          <EuiTitle size='s'>
            <h3>Campaign Delivery Strategy</h3>
          </EuiTitle>
          <EuiSpacer />
          <CampaignDeliveryForm campaignDelivery={tvTargeting} onCampaignDeliveryChanged={onCampaignDeliveryChange} />
        </>
      )}
    </>
  )
}
