import { useEffect, useState } from 'react'

import { EuiCheckbox, EuiFormRow, EuiSpacer, EuiSwitch, EuiTitle } from '@elastic/eui'

import { AudienceTargeting, BaseTargeting, DayPartitioning, LocationTargeting } from '../../api/entities/Targeting'
import { DEFAULT_AUDIENCE_TARGETING, DEFAULT_LOCATION_TARGETING } from '../../utils/TargetingFormDefaults'
import { AudienceTargetingForm } from './AudienceTargetingForm'
import { DayPartPicker } from './DayPartPicker'
import { LocationTargetingForm } from './LocationTargetingForm'

type BillboardTargetingFormProps = {
  billboardTargeting: BaseTargeting
  onBillboardTargetingChanged: (billboardTargeting: BaseTargeting) => void
}

export const BillboardTargetingForm = ({ billboardTargeting, onBillboardTargetingChanged }: BillboardTargetingFormProps) => {
  const [editCampaignDelivery, setEditCampaignDelivery] = useState(false)
  const [editLocationTargeting, setEditLocationTargeting] = useState(!!billboardTargeting.locationTargeting)
  const [editDemographics, setEditDemographics] = useState(!!billboardTargeting.audienceTargeting)

  const onDayPartsChange = (dayParts: DayPartitioning) => {
    onBillboardTargetingChanged({
      ...billboardTargeting,
      dayParts
    })
  }

  const onLocationTargetingChange = (locationTargeting: LocationTargeting) => {
    onBillboardTargetingChanged({
      ...billboardTargeting,
      locationTargeting
    })
  }

  const onDemographicsChange = (audienceTargeting: AudienceTargeting) => {
    onBillboardTargetingChanged({
      ...billboardTargeting,
      audienceTargeting
    })
  }

  useEffect(() => {
    if (!editLocationTargeting) {
      onBillboardTargetingChanged({
        ...billboardTargeting,
        locationTargeting: undefined
      })
    } else if (!billboardTargeting.locationTargeting) {
      onBillboardTargetingChanged({
        ...billboardTargeting,
        locationTargeting: DEFAULT_LOCATION_TARGETING
      })
    }
  }, [editLocationTargeting])

  useEffect(() => {
    if (!editDemographics) {
      onBillboardTargetingChanged({
        ...billboardTargeting,
        audienceTargeting: undefined
      })
    } else if (!billboardTargeting.audienceTargeting) {
      onBillboardTargetingChanged({
        ...billboardTargeting,
        audienceTargeting: DEFAULT_AUDIENCE_TARGETING
      })
    }
  }, [editDemographics])

  return (
    <>
      <EuiTitle size={'s'}>
        <h3>Location Targeting</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiCheckbox id={'editInternetLocationTargeting'} onChange={() => setEditLocationTargeting(!editLocationTargeting)} checked={editLocationTargeting} label={'Override'} />
      <EuiSpacer size={'xs'} />
      {editLocationTargeting && <LocationTargetingForm locationTargeting={billboardTargeting.locationTargeting ?? DEFAULT_LOCATION_TARGETING} onLocationTargetingChange={onLocationTargetingChange} />}
      <EuiSpacer />
      <EuiTitle size={'s'}>
        <h3>Demographics</h3>
      </EuiTitle>
      <EuiSpacer size={'xs'} />
      <EuiCheckbox id={'editDemographicsTargeting'} onChange={() => setEditDemographics(!editDemographics)} checked={editDemographics} label={'Override'} />
      <EuiSpacer size='xs' />
      {editDemographics && <AudienceTargetingForm audienceTargeting={billboardTargeting.audienceTargeting ?? DEFAULT_AUDIENCE_TARGETING} onAudienceTargetingChanged={onDemographicsChange} />}
      <EuiSpacer />
      <EuiSwitch label={'Edit Campaign Delivery Strategy'} checked={editCampaignDelivery} onChange={() => setEditCampaignDelivery(!editCampaignDelivery)} />
      <EuiSpacer />
      {editCampaignDelivery && (
        <>
          <EuiTitle size='s'>
            <h3>Campaign Delivery Strategy</h3>
          </EuiTitle>
          <EuiSpacer />
          <EuiFormRow label='Day Partitioning'>
            <DayPartPicker onSelectedDayPartsChange={onDayPartsChange} dayParts={billboardTargeting.dayParts} cellWidth={24} />
          </EuiFormRow>
        </>
      )}
    </>
  )
}
